<template>
    <div class="company xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>公司介绍</h3>
                    <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>公司介绍</a></p>
                </div>
            </div>
        </div>
        <div class="company-about">
            <div class="about-wrap">
                <div class="company-tit">关于千越</div>
                <div class="company-about-box">
                    <div class="company-name">
                        <h3>河南千越医疗科技有限公司</h3>
                        <h4>henan qianyueyiliao youxiangongsi</h4>
                    </div>
                    <div class="about-mes clearfix">
                        <div class="left">
                            <p>河南千越医疗科技有限公司是专门从事数字健康与医疗信息化技术产品研发与推广的高科技企业，主要致力于自有知识产权的应用型软件研发、销售和服务，是中国数字化全医学检索的领航者，通过《基层医疗电子化检索系统》来推动中国医疗改革的发展。<br>公司集科研、生产、销售、服务为一体，拥有一支专业技术领先、人员稳定、工作高效的技术队伍。公司始终秉承“专注、一流、协作、创新”的经营理念，密切关注国内外医疗信息发展的最新动态，始终掌握核心技术，注重自主知识产权产品的开发研制，不断开拓创新。</p>
                            <p>公司的经营发展重点在区域卫生信息，临床医疗信息（电子病历），医疗质量，合理用药，医学图象临床检验及辅助诊断、三级转诊、慢病管理等医疗卫生信息数字化管理软件系统方面；并且全心全意与政府和医院合作，共同推进医疗卫生数字化建设。</p>
                            <p>公司坚持贯彻“质量、价格、服务、诚信”的营销理念，坚持不断完善产品的功能，不断提高售后服务质量与速度，从而极大促进了广大用户管理手段和水平的不断提高。凭借独特的市场眼光和敢于创新的企业精神，公司已经成为了中国数字化全医学检索建设不可缺少的一员，是中国行业软件开发后起之秀。目前，公司已经成功在国内各地建立30多家代理商、1000余家二三级销售商 。</p>
                            <p>我们更期待：与广大客户一起共创更加辉煌的明天！</p>
                        </div>
                        <div class="video right">
                            <img style="margin-top: 25%;" src="../../assets/img/company_video_img.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="culture">
            <ul>
                <li style="text-align: right;right: 710px;top:30px;">企业价值观<br>
                    以人为本 、诚信为先，快乐工作，愉悦生活</li>
                <li style="text-align: right;right: 710px;top:190px;">研发理念<br>
                    专注客户体验，创新引领技术</li>
                <li style="text-align: right;right: 710px;top:325px;">企业精神<br>
                    专注、一流、协作、创新</li>
                <li style="left: 710px;top: 30px;">企业愿景<br>
                    普及全民医疗知识，服务大众健康。</li>
                <li style="left: 710px;top: 190px;">企业使命<br>
                    做数字化全医学检索的领航者，推动中国医疗改革的发展。</li>
                <li style="left: 710px;top: 325px;">团队精神<br>
                    众志成城 飞越颠峰</li>
            </ul>
        </div>
        <div class="company-adv">
            <div class="adv-box">
                <h3>我们的优势</h3>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <div class="adv-item">
                            <img src="../../assets/img/company_icon1.png" alt="">
                            <h5>完善的数据整理与收集团队</h5>
                            <p>系统能够检索11164种病症，<br>
                                13937种药品说明及全科医学知识，<br>
                                解决知识盲点，避免遗漏。</p>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="adv-item">
                            <img src="../../assets/img/company_icon2.png" alt="">
                            <h5>精准无误的医疗大数据</h5>
                            <p>根据疑似疾病精准检查，<br>
                                通过鉴别诊断快速确定疾病。</p>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="adv-item">
                            <img src="../../assets/img/company_icon3.png" alt="">
                            <h5>专业医生顾问团队</h5>
                            <p>我们拥有一支强大的技术开发团队，<br>具有十余年的行业开发经验</p>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="adv-item">
                            <img src="../../assets/img/company_icon4.png" alt="">
                            <h5>大量的用户基础</h5>
                            <p>大量用户助力产品的内在提升。</p>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="adv-item">
                            <img src="../../assets/img/company_icon5.png" alt="">
                            <h5>强大的技术开发团队</h5>
                            <p>高效快速检索出所需知识，<br>
                                辅助医疗。</p>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="adv-item">
                            <img src="../../assets/img/company_icon6.png" alt="">
                            <h5>人工智能与大数据技术的完美结合</h5>
                            <p>人工智能与大数据技术的完美结合</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import ftCom from '../../components/footer';
    import nhCom from "../../components/navheader";
    import rtBar from "../../components/right";
    export default {
        name: "company",
        components: {
            ftCom,
            nhCom,
            rtBar
        },
    }
</script>

<style scoped>
    .company{
        background: #f4f4f4;
    }
    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg")center no-repeat;
    }

    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

    .banner-text h3 {
        padding-top: 35px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
/*--------*/
    .company-about{
        margin-top: 50px;
        padding-top: 150px;
        background: url("../../assets/img/company_about.png")top center no-repeat;
        background-size: 100% auto;
    }
    .about-wrap{
        width: 1200px;
        margin: 0 auto;
    }
    .company-tit{
        margin-bottom: 40px;
        font-size: 30px;
        line-height: 30px;
        color: #FFFFFF;
        font-weight: bold;
        border-left: 4px solid #1fb7a6;
        padding-left: 10px;
    }
    .company-about-box{
        background: #FFFFFF;
        border-radius: 5px;
    }
    .company-about-box .company-name{
        padding: 25px 0;
        border-bottom: 1px solid #efefef;
    }
    .company-about-box .company-name h3{
        line-height: 1.5;
        font-family: SimSun;
        font-size: 28px;
        text-align: center;
        color: #01c2ac;
    }
    .company-about-box .company-name h4{
        font-weight: normal;
        font-size: 18px;
        text-align: center;
        color: #cccccc;
    }
    .about-mes{
        padding: 35px;
        margin-bottom: 50px;
    }
    .about-mes div.left{
        padding: 0 14px;
        width: 740px;
        border-right: 1px solid #e5e5e5;
    }
    .about-mes div.left p{
        line-height: 2.3;
        text-indent: 28px;
    }
/*--------*/
    .culture{
        height: 470px;
        width: 100%;
        background: url("../../assets/img/compang_adv.png")top center no-repeat;
    }
    .culture ul{
        position: relative;
        width: 1200px;
        margin: 0 auto;
    }
    .culture ul li{
        position: absolute;
        color: #FFFFFF;
        font-size: 18px;
    }
/*--------*/
    .company-adv{
        padding: 20px 0;
    }
    .adv-box{
        width: 1200px;
        margin: 0 auto;
    }
    .adv-box h3{
        padding: 25px;
        font-family: SimSun;
        font-size: 28px;
        text-align: center;
        color: #01c2ac;
    }
    .adv-item{
        margin: 15px 0;
        text-align: center;
        height: 280px;
    }
    .adv-item h5{
        color: #1fb7a6;
        font-size: 18px;
        padding: 10px;
    }
    .adv-item p{
        line-height: 1.7;
    }
</style>
